import { Component, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutComponent from './components/Layout/LayoutComponent/LayoutComponent';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalDataContext from '#resources/context/GlobalDataContext';
import themeDark from '#resources/themes/themeDark';
import themeLight from '#resources/themes/themeLight';
import ProjectsPage from '#pages/ProjectsPage/ProjectsPage';
import ResumePage from '#pages/ResumePage/ResumePage';
import HomePage from '#pages/HomePage/HomePage';
import NetworkPage from '#pages/NetworkPage/NetworkPage';

class App extends Component {
  static contextType = GlobalDataContext;

  render() {
    return (
      <ThemeProvider theme={this.context.theme == 'dark' ? themeDark : themeLight}>
        <CssBaseline />
        <Suspense>
          <Routes>
            <Route path='/' element={<LayoutComponent />}>
              <Route path='Projects' element={<ProjectsPage />} />
              <Route path='Resume' element={<ResumePage />} />
              <Route path='Network' element={<NetworkPage />} />

              <Route path='/' element={<HomePage />} />
            </Route>
          </Routes>
        </Suspense>
      </ThemeProvider>
    );
  }
}

export default App;
