import { useState } from 'react';
import GlobalDataContext from '#resources/context/GlobalDataContext';

const GlobalDataContextProvider = ({ children }) => {
  const [data, setData] = useState({}); // Initial state can be anything you want
  const [theme, setTheme] = useState('light'); // Initial state can be anything you want

  // Function to manipulate data

  return (
    <GlobalDataContext.Provider
      value={{
        data,
        setData,
        theme,
        setTheme
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataContextProvider;
