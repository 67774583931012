import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const navList = [
  { label: 'Home', value: '/' },
  { label: 'Projects', value: '/Projects' },
  { label: 'Resume', value: '/Resume' },
  { label: 'Network', value: '/Network' }
];
const NavigationBar = () => {
  const [panel, setPanel] = React.useState(window.location.pathname);

  const handleChange = (event, newValue) => {
    setPanel(newValue);
  };
  return (
    <>
      <Box
        flexGrow={1}
        display={'flex'}
        flexWrap={'wrap'}
        flexBasis={'100%'}
        justifyContent='center'
      >
        <Tabs
          value={panel}
          onChange={handleChange}
          textColor='primary'
          indicatorColor='primary'
        >
          {navList &&
            navList.map((x) => {
              return (
                <Tab
                  key={x.value}
                  value={x.value}
                  component={Link}
                  label={x.label}
                  to={x.value}
                />
              );
            })}
        </Tabs>
      </Box>
    </>
  );
};

export default NavigationBar;
