import { Typography, useMediaQuery } from '@mui/material';
import { buyMeCoffeeLogoTransparent } from '#resources/images';
import { buyMeCoffeeQRCodeSmall } from '#resources/images';

const style = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  background: themeDark.palette.secondary.main
};
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import themeDark from '#resources/themes/themeDark';
const FooterComponent = () => {
  const navigate = useNavigate();
  const desktop = useMediaQuery('(min-width:600px)');

  return (
    <Box
      sx={{ ...style }}
      mt={1}
      p={1}
      flexGrow={1}
      display={'flex'}
      flexWrap={'nowrap'}
      flexBasis={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        m={1}
        component='img'
        sx={{
          height: 35,
          width: 35
        }}
        alt='Donation QR Code'
        src={buyMeCoffeeLogoTransparent}
        onClick={() => navigate('https://www.buymeacoffee.com/drewology')}
      />
      <Box ml={1} maxWidth={800} flexShrink={1}>
        {desktop && (
          <Typography variant='body2'>
            I love making web apps that are easy to use and help people. If you did,
            consider just offering a coffee for the day.
          </Typography>
        )}
        <Typography variant='body2'>
          Contact Me: superior.drewology@gmail.com or trueleo1@gmail.com
        </Typography>
      </Box>
      {desktop && (
        <Box
          m={1}
          component='img'
          sx={{
            height: 50,
            width: 50
          }}
          alt='Donation QR Code'
          src={buyMeCoffeeQRCodeSmall}
          onClick={() => navigate('https://www.buymeacoffee.com/drewology')}
        />
      )}
    </Box>
  );
};

export default FooterComponent;
