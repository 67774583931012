import { createTheme } from '@mui/material/styles';

const themeDark = createTheme({
  "components": {
    "MuiCssBaseline": {
      "styleOverrides": {
        "body": {
          "marginBottom": "30px"
        }
      }
    }
  },
  "typography": {
    "fontFamily": "readingFont",
    "fontSize": 12
  },
  "palette": {
    "mode": "dark",
    
    "primary": {
      "main": "#113A8C",
      "contrastText": "#fff"
    },
    "secondary": {
      "main": "#7C92A6",
      "contrastText": "#fff"

    },
    "background": {
      "default": "#283040"
    },
    "text": {
      "primary": "#000"
    },
    "paper":{
      "default": "#283040"
    }
    
  }
})

export default themeDark;