import { Box, Paper, Typography } from '@mui/material';
import themeDark from '#resources/themes/themeDark';

const SkillsList = [
  {
    value: 'Frontend',
    subLanguages: [
      {
        value: 'Javascript',
        subfields: ['React', 'Vue', 'Cypress', 'Playwright']
      }
    ]
  },
  {
    value: 'Back-End',
    subLanguages: [
      {
        value: 'Java',
        subfields: ['SpringBoot', 'Junit Testing', 'Rest', 'Maven']
      }
    ]
  },
  {
    value: 'Database',
    subLanguages: [
      {
        value: 'SQL',
        subfields: ['PostgresSQL', 'Oracle']
      }
    ]
  },
  {
    value: 'Project Management',
    subLanguages: [
      {
        value: 'Jira',
        subfields: []
      },
      {
        value: 'Azure Dev Ops',
        subfields: []
      }
    ]
  },
  {
    value: 'Deployment Infrasturucture',
    subLanguages: [
      {
        value: 'AWS',
        subfields: []
      },
      {
        value: 'Firebase',
        subfields: []
      },
      {
        value: 'Concourse',
        subfields: []
      },
      {
        value: 'Azure Dev Ops',
        subfields: []
      }
    ]
  },
  {
    value: 'Version Control',
    subLanguages: [
      {
        value: 'Git',
        subfields: []
      }
    ]
  }
];

const SkillsCard = () => {
  return (
    <Box mb={1}>
      <Paper elevation={8} sx={{ background: themeDark.palette.Paper }}>
        <Box p={1}>
          <Box m={1} flexBasis={'100%'}>
            <Typography textAlign={'center'} variant='h6'>
              Skills
            </Typography>
          </Box>
          <Box m={1}>
            {SkillsList &&
              SkillsList.map((item, index) => {
                return (
                  <Box key={index}>
                    <Box>
                      <Typography variant='subtitle1'>{item.value}</Typography>
                    </Box>
                    {item.subLanguages &&
                      item.subLanguages.map((item2, index2) => {
                        return (
                          <Box key={index2}>
                            <Box ml={3} key={index2}>
                              <Typography key={index2} variant='subtitle2'>
                                &#x2022; {item2.value}
                              </Typography>
                            </Box>
                            {item2.subfields &&
                              item2.subfields.map((item3, index3) => {
                                return (
                                  <Box ml={6} key={index3}>
                                    <Typography key={index3} variant='body2'>
                                      &#x2022; {item3}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SkillsCard;
