import { Box, Fade, Link } from '@mui/material';
import instagramLogo from '#resources/images/instagramLogoTransparent.png';
import buyMeCoffeeLogo from '#resources/images/buymeCoffeeLogoTransparent.png';
// import facebookLogo from '#resources/images/facebookLogo.png';
import linkedInLogo from '#resources/images/linkedInLogo.png';

const SocialMediaLinks = () => {
  return (
    <Box height={45}>
      <Link href={'https://www.instagram.com/superior_drewology/'}>
        <Fade in={true} timeout={500} style={{ transitionDelay: '1s' }}>
          <Box
            m={1}
            flexGrow={0}
            component='img'
            sx={{
              height: 25,
              width: 25
            }}
            alt='The house from the offer.'
            src={instagramLogo}
          />
        </Fade>
      </Link>
      <Link href={'https://www.buymeacoffee.com/drewology'}>
        <Fade in={true} timeout={500} style={{ transitionDelay: '2s' }}>
          <Box
            m={1}
            flexGrow={0}
            component='img'
            sx={{
              height: 25,
              width: 25
            }}
            alt='The house from the offer.'
            src={buyMeCoffeeLogo}
          />
        </Fade>
      </Link>
      <Link href={'https://www.linkedin.com/in/andrew-keller-23132a12/'}>
        <Fade in={true} timeout={500} style={{ transitionDelay: '3s' }}>
          <Box
            m={1}
            flexGrow={0}
            component='img'
            sx={{
              height: 25,
              width: 25
            }}
            alt='The house from the offer.'
            src={linkedInLogo}
          />
        </Fade>
      </Link>
    </Box>
  );
};

export default SocialMediaLinks;
