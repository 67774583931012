import {
  Box,
  Typography,
  Fade,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  useMediaQuery
} from '@mui/material';
import resumeStock from '#resources/images/resumeStock.webp';
import projectStock from '#resources/images/projectStock.webp';
import networkStock from '#resources/images/networkStock.webp';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleNav = (nav) => {
    navigate(nav);
  };

  const desktop = useMediaQuery('(min-width:600px)');

  return (
    <Box display={'flex'} flexWrap={'wrap'} flexBasis={'100%'} justifyContent='center'>
      <Box mt={2} flexBasis={'100%'}>
        <Fade in={true} timeout={2000}>
          <Typography variant='h3' textAlign={'center'}>
            Andrew P Keller
          </Typography>
        </Fade>
      </Box>

      <Box m={1} flexBasis={'100%'} width={200}>
        <Fade in={true} timeout={2000} style={{}}>
          <Typography variant='body2' textAlign={'center'}>
            Welcome to my digital portfolio. I craft solutions that marry innovation with
            simplicity, aiming to enhance everyday life. My work is a bridge between
            technology and people, making the complex accessible and enjoyable. Join me in
            shaping experiences that matter.
          </Typography>
        </Fade>
      </Box>

      <Box
        display={'flex'}
        flexWrap={'wrap'}
        m={2}
        flexBasis={'100%'}
        maxWidth={800}
        justifyContent={desktop ? 'space-between' : 'center'}
      >
        <Box m={1}>
          <Card raised sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => handleNav('/Projects')}>
              <CardMedia
                sx={{ height: 100, width: 200 }}
                image={projectStock}
                title='green iguana'
              />
              <CardContent sx={{ width: 200 }}>
                <Typography gutterBottom variant='h5' component='div'>
                  Projects
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box m={1}>
          <Card raised sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => handleNav('/Resume')}>
              <CardMedia
                sx={{ height: 100, width: 200 }}
                image={resumeStock}
                title='green iguana'
              />
              <CardContent sx={{ width: 200 }}>
                <Typography gutterBottom variant='h5' component='div'>
                  Resume
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box m={1}>
          <Card raised sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => handleNav('/Network')}>
              <CardMedia
                sx={{ height: 100, width: 200 }}
                image={networkStock}
                title='green iguana'
              />
              <CardContent sx={{ width: 200 }}>
                <Typography gutterBottom variant='h5' component='div'>
                  Network
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
